import React, { useState } from 'react'
import ScreenShare from '@material-ui/icons/ScreenShare'

import Button from '@eig-builder/core-ui/Button'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import SEO from './../components/seo'
import Layout from './../components/layout'
import Hero from './../components/home/Hero'
import TagLine from './../components/tagline'
import InPageImage from './../components/in-page-image'
import DiscountTerms from './../components/discount-terms'
import PopPup from './../components/pop-up'

const IndexPage = () => {
  const [callToAction, setCallToAction] = useState(false)
  const [callToActionB, setCallToActionB] = useState(false)

  const redirectWebdotcom = (websiteUrl, setCall) => {
    setTimeout(() => {
      document.location.href = websiteUrl
      setCall(false)
    }, 5000)
  }

  return (
    <Layout isWhiteHeader>
      <React.Fragment>
        <SEO
          title='Create Your Own Free Website in Minutes'
          keywords={[`web hosting`, `domain names`, `web site`, `search engine optimization`, `hosting`, `servers`]}
          pageType={'home'}
          flowType={'none'} />
        <Hero />
        <section className='home-page bg-mint'>
          <div className='container section-padding text-section large-gutter home'>
            <TagLine title={'Awesome & Easy. Try it first. Then decide.'} tagSize={'h2'} />
            <div className='row'>
              <div className='col-md-6'>
                <h3>We’ll instantly create a perfect design</h3>
                <p className='black-60'>
                  Our intelligent builder does all the heavy lifting to create a personalized site, just for you.
                </p>
                <p className='black-60'>
                  Answer a few short questions and it will choose the right layout, pull in beautiful images, and even start your content.
                </p>
                <a href='https://sailingsoundwave.com/' rel='noopener' target='_blank'>
                  <ScreenShare className='screen-share' fontSize='small' /> Real customer example
                </a>
              </div>
              <div className='col-md-6 mt-0 image'><InPageImage className='no-shadow' fileName='screenshot_01.png' alt='express editor' /></div>
            </div>
            <div className='row'>
              <div className='col-md-6 image'><InPageImage className='no-shadow' fileName='screenshot_02.png' alt='express editor' /></div>
              <div className='col-md-6 swap'>
                <h3>Review and make it your own</h3>
                <p className='black-60'>
                  Keep what works, change what doesn’t. Put a few final finishes on it to truly make it your own. We make it simple to return and edit pages later on.
                </p>
                <a href='https://indyfusedglass.com/' rel='noopener' target='_blank'>
                  <ScreenShare className='screen-share' fontSize='small' /> Real customer example
                </a>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 mb-0'>
                <h3>Share your ideas. Grow your brand</h3>
                <p className='black-60'>
                  Our sites look awesome on any device. They’re super fast to help you get noticed by Google. We make it easy to update and expand without the need for specialized technical knowledge.
                </p>
                <p className='black-60'>
                  Once your site is published, we provide all the tools to grow. We help you get site traffic and engage your visitors.
                </p>
                <a href='https://kattenfort.nl/' rel='noopener' target='_blank'>
                  <ScreenShare className='screen-share' fontSize='small' /> Real customer example
                </a>
              </div>
              <div className='col-md-6 image'><InPageImage className='no-shadow' fileName='screenshot_03.png' alt='get social' /></div>
            </div>
          </div>
        </section>
        <section>
          <div className='container section-padding text-section large-gutter home'>
            <TagLine title={'Want to start selling online? We got you covered'} tagSize={'h2'} />
            <div className='row' style={{ alignItems: 'center' }}>
              <div className='col-md-6 image' style={{ marginTop: 0, marginBottom: 0 }}><InPageImage className='no-shadow' fileName='screenshot_04.png' alt='create a store' /></div>
              <div className='col-md-6 swap'>
                <h3>Create a store and start selling online</h3>
                <p className='black-60'>
                  Whether you sell songs, pet sitting or custom clothing, we can power your business. We give you all of the tools to sell, accept credit card payments, and manage your online business.
                </p>
                <p className='black-60'>
                  We’ll help you handle sales tax, order fulfillment, inventory, product categories, shipping and more.</p>
                <Button
                  dataElementLocation={DataElementLocations.BODY}
                  dataElementLabel={'learn-more'}
                  dataElementId={'home-page-learn-more-about-selling-online'}
                  color='cta'
                  variant='contained'
                  className='signup-button'
                  size='large'
                  onClick={() => {
                    setCallToAction(true)
                    redirectWebdotcom('https://www.web.com/websites/online-store-builder', setCallToAction)
                  }}
                >Learn more</Button>
                <PopPup callToAction={callToAction} setCallToActionB={setCallToAction} />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='container section-padding text-section large-gutter home'>
            <TagLine title={'Get your website built by Web.com'} tagSize={'h2'} />
            <div className='row' style={{ alignItems: 'center' }}>
              <div className='col-md-6 swap'>
                <h3>Hire an expert to build your website</h3>
                <p className='black-60'>
                  Web.com offers flexible and affordable custom website design services backed by a team of experts available to support your business needs. Let our team of experienced professionals build your website and establish your online presence.
                </p>
                <Button
                  dataElementLocation={DataElementLocations.BODY}
                  dataElementLabel={'learn-more'}
                  dataElementId={'home-page-learn-more-about-hire-expert'}
                  color='cta'
                  variant='contained'
                  className='signup-button'
                  size='large'
                  onClick={() => {
                    setCallToActionB(true)
                    redirectWebdotcom('https://www.web.com/websites/pro-website-services', setCallToActionB)
                  }}
                >Learn more</Button>
                <PopPup callToAction={callToActionB} setCallToActionB={setCallToActionB} />
              </div>
              <div className='col-md-6 image' style={{ marginTop: 0, marginBottom: 0 }}><InPageImage className='no-shadow' fileName='screenshot_05.png' alt='Hire a expert' /></div>
            </div>
          </div>
          <DiscountTerms />
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default IndexPage
