import styled from 'styled-components'
import { Link } from 'gatsby'
import Button from '@eig-builder/core-ui/Button'

const Action = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  display: inline-block;

  .arrow-foward {
    margin-left: 10px;
  }
`

Action.Button = styled(Button).attrs(() => ({
  variant: 'contained',
  size: 'xl',
  color: 'primary',
}))`
  border-radius: 5px !important;
  min-height: 50px !important;
  @media only screen and (min-width: 800px) {
    padding: 0px 20px !important;
  }

  .MuiButton-label {
    display: flex !important;
    flex-direction: column !important;
  }
`

Action.Link = styled(Link).attrs()`
  color: blue;
  font-size: 14px;
  font-weight: bold;
`

export default Action
