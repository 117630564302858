import { useStaticQuery, graphql } from 'gatsby'

const useGetHeroImage = () =>
  useStaticQuery(graphql`
    query HeroImg {
      image: file(relativePath: { eq: "homeBanner.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

export default useGetHeroImage
