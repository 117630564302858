import React from 'react'
import PropTypes from 'prop-types'
import Panel from './panel.styles'

const HomePanel = ({
  children,
  gatsbyImage,
  backgroundImage,
  alignment = 'left',
}) => {
  return (
    <Panel alignment={alignment} backgroundImage={backgroundImage}>
      <Panel.section>
        { children }
      </Panel.section>
      <Panel.section image>
        <Panel.image fluid={gatsbyImage} alt='home hero image' />
      </Panel.section>
    </Panel>
  )
}

HomePanel.propTypes = {
  gatsbyImage: PropTypes.object.isRequired,
  backgroundImage: PropTypes.string,
  alignment: PropTypes.string,
  children: PropTypes.node
}

export default HomePanel
