import React, { useState } from 'react'
import { Link } from 'gatsby'

import Typography from '@eig-builder/core-ui/Typography'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import useMobileDetection from '../../helpers/useMobileDetection'

import Action from './action.styles'
import PopUpCallToAction from '../pop-up'

const CallToAction = () => {
  const [isMobile] = useMobileDetection(800)
  const [callToAction, setCallToAction] = useState(false)

  const redirectWebdotcom = websiteUrl => {
    setTimeout(() => {
      document.location.href = websiteUrl
      setCallToAction(false)
    }, 5000)
  }

  return (
    <>
      <Action>
        <Link onClick={() => setCallToAction(true)} style={{ textDecoration: 'none' }}>
          <Action.Button
            onClick={() => {
              setCallToAction(true)
              redirectWebdotcom('https://www.web.com/websites')
            }}
            dataElementLocation={DataElementLocations.BODY}
            dataElementLabel='body.call-to-action'
            dataElementId='websitebuilder-call-to-action-button'
          >
            <Typography variant={isMobile ? 'h4' : 'h4'}>
              Get a Website Now
            </Typography>
          </Action.Button>
        </Link>
      </Action>
      <PopUpCallToAction
        callToAction={callToAction}
        setCallToActionB={setCallToAction}
        absoluteLeftPosition={isMobile ? null : '40px'}
        absoluteTopPosition={isMobile ? '-65px' : '-40px'}
      />
    </>
  )
}

export default CallToAction
