import styled, { css } from 'styled-components'
import Img from 'gatsby-image'

const Panel = styled.div`
  display: flex;
  background-color: ${ props => (props.dark ? '#f6f6f6' : '#fff') };
  ${ ({ backgroundImage }) => backgroundImage && css`
    background-image: url("${ backgroundImage }");
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 800px) {
      background-position: inherit;
    }
  ` }
  justify-content: center;
  align-items: center;
  flex-direction: ${ props =>
    props.alignment === 'left' ? 'row' : 'row-reverse' };
  flex-wrap: wrap-reverse;
  padding: 80px ${ ({ theme }) => theme.spacing(4) }px;
  overflow: hidden;
  @media (max-width: 800px) {
    padding: 50px 0;
  }
`
Panel.section = styled.div`
  position: relative;
  min-width: 400px;
  max-width: 600px;
  padding: 10px 20px;
  text-align: left;
  flex: 1 1 0;
  max-width: 500px;
  @media (max-width: 400px) {
    min-width: 320px;
  }

  ${ ({ image }) => image && css`
    max-width: 700px;
    @media (max-width: 800px) {
      padding: 0;
    }
  ` }
`
Panel.title = styled.p`
  margin-top: 0;
  font-size: 24pt;
  font-weight: bold;
  color: #333;
`
Panel.image = styled(Img)`
  max-width: 100%;
`
export default Panel
