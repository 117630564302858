import React from 'react'
import styled from 'styled-components'

import useFetchAsJson from '@eig-builder/core-utils/hooks/useFetchAsJson'
import Typography from '@eig-builder/core-ui/Typography'

import HomePanel from './../home-panel'
import useMobileDetection from '../../helpers/useMobileDetection'

import useGetHeroImage from './useGetHeroImage'
import CallToAction from './CallToAction'

import circles from './../../images/twoCircle.svg'
import circle from './../../images/circle.svg'

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 800px) {
    place-items: center;
  }
`

const HeroH1 = styled(Typography).attrs({ variant: 'h1' })`
  @media only screen and (max-width: 600px) {
    text-align: center;
  }
`

const HeroBody = styled(Typography).attrs({ variant: 'body1' })`
  @media only screen and (max-width: 600px) {
    text-align: center;
  }
`

const SocialProofNonFlapHero = () => {
  const url = `${ process.env.GATSBY_SOCIAL_PROOF_API_BASE_URL }/v1.0/fos_accounts_stats/sites_created/websitebuilder/OneMonth`
  const fetchOptions = {
    url,
    useCache: true,
  }

  const [{ isLoading, data }] = useFetchAsJson(fetchOptions)
  const img = useGetHeroImage()
  const [isMobile] = useMobileDetection(800)
  const defaultSitesPublished = '10,000+'

  // if loading, spin random numbers for effect, if done loading and data: data, if done loading and error: 10,000+
  const getVal = () =>
    isLoading
      ? defaultSitesPublished
      : data
        ? data.toLocaleString()
        : defaultSitesPublished

  return (
    <HomePanel
      gatsbyImage={img.image.childImageSharp.fluid}
      backgroundImage={isMobile ? circle : circles}
    >
      <HeroContent>
        <HeroH1>
          Create the perfect website in minutes
        </HeroH1>
        <HeroBody>
          {getVal()} websites created in the last 30 days alone!
        </HeroBody>
        <CallToAction />
      </HeroContent>
    </HomePanel>
  )
}

export default SocialProofNonFlapHero
